import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { withAuthenticator } from "aws-amplify-react";

import Routes from "./Routes";
import { user, company } from "@displaygecko/dg-modules";

function App({ getUser, getCompany }) {
  useEffect(() => {
    getUser();
    getCompany();
  }, [getCompany, getUser]);

  return (
    <Router>
      <Routes />
    </Router>
  );
}

const mapDispatch = {
  getUser: user.getUser,
  getCompany: company.getCompany
};

const connectedApp = connect(null, mapDispatch)(App);

export default withAuthenticator(connectedApp, { usernameAttributes: "email" });
