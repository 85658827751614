export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const SET_SCREENS_SILENTLY = "SET_SCREENS_SILENTLY";
export const GET_ALL_SCREENS = "GET_ALL_SCREENS";
export const GET_SCREEN = "GET_SCREEN";
export const UPDATE_SCREEN = "UPDATE_SCREEN";
export const DELETE_SCREEN = "DELETE_SCREEN";
export const REORDER_SCREENS = "REORDER_SCREENS";
export const CREATE_SCREEN_FROM_TEMPLATE = "CREATE_SCREEN_FROM_TEMPLATE";
export const GET_VERSIONS = "GET_VERSIONS";