import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  useTheme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  Settings as SettingsIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Help as HelpIcon,
  Home as HomeIcon,
  ViewQuilt as ViewQuiteIcon
} from "@material-ui/icons";
import Auth from "@aws-amplify/auth";

import { ColumnFlexBox, HelpDock } from "@displaygecko/dg-components";
import { useToggle } from "../../helpers";

function MainLayout({ match, location, children }) {
  const theme = useTheme();

  const {
    on: openHelpDialog,
    setOn: showHelpDialog,
    setOff: closeHelpDialog
  } = useToggle();

  const handleOpenHelp = useCallback(() => {
    showHelpDialog();
  }, [showHelpDialog]);

  const handleSignOut = useCallback(() => {
    Auth.signOut();
  }, []);

  const isConfigPage = match.url === "/config";
  let goBackTooltipTitle = "Settings";
  let goBackPath = "/config";
  let GoBackIcon = SettingsIcon;
  if (isConfigPage) {
    goBackTooltipTitle =
      !location.state || location.state.previousPath === "/"
        ? "Dashboard"
        : "Screen";
    goBackPath = !location.state ? "/" : location.state.previousPath;
    if (!location.state || location.state.previousPath === "/") {
      GoBackIcon = HomeIcon;
    } else {
      GoBackIcon = ViewQuiteIcon;
    }
  }

  return (
    <ColumnFlexBox>
      <AppBar position="static">
        <Toolbar variant="dense">
          <NavLink to="/">
            <Typography>Display Gecko</Typography>
          </NavLink>
          <Box flexGrow={1} />
          <Tooltip title={goBackTooltipTitle}>
            <NavLink
              to={
                isConfigPage
                  ? goBackPath
                  : {
                      pathname: "/config",
                      state: { previousPath: match.url }
                    }
              }
            >
              <IconButton aria-label="configurations" color="inherit">
                <GoBackIcon />
              </IconButton>
            </NavLink>
          </Tooltip>
          <Tooltip title="Sign Out">
            <IconButton
              aria-label="sign out"
              color="inherit"
              onClick={handleSignOut}
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box position="relative" zIndex={1}>
        <Box position="absolute" right={theme.spacing(3)}>
          <IconButton
            aria-label="help"
            color="inherit"
            onClick={handleOpenHelp}
          >
            <HelpIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {children}
      <HelpDock open={openHelpDialog} onRequestClose={closeHelpDialog} />
    </ColumnFlexBox>
  );
}

export default withRouter(MainLayout);
