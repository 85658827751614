import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Paper, Grid, Typography, Tooltip } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import { DragDropContext } from "react-beautiful-dnd";

import {
  ScreenList,
  ScreenTemplateList,
  ConfirmationDialog
} from "@displaygecko/dg-components";
import { template, screen } from "@displaygecko/dg-modules";

import { useToggle } from "../../helpers";

import useStyles from "./dashboard.styles";

const mapState = state => ({
  templateList: template.templateListSelector(state),
  screenList: screen.screenListSelector(state)
});

const mapDispatch = {
  getAllTemplates: template.getAllTemplates,
  getAllScreens: screen.getAllScreens,
  reorderScreens: screen.reorderScreens,
  createScreenFromTemplate: screen.createScreenFromTemplate,
  updateScreen: screen.updateScreen,
  deleteScreen: screen.deleteScreen
};

function Dashboard({
  templateList,
  screenList,
  getAllTemplates,
  getAllScreens,
  reorderScreens,
  createScreenFromTemplate,
  updateScreen,
  deleteScreen
}) {
  const classes = useStyles({});

  const [state, setState] = useState({
    confirmDialogOptions: {}
  });
  const {
    on: confirmDialogOpen,
    setOn: showConfirmDialog,
    setOff: closeConfirmDialog
  } = useToggle();

  useEffect(() => {
    getAllTemplates();
    getAllScreens();
  }, [getAllTemplates, getAllScreens]);

  const onDragEnd = useCallback(
    result => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          reorderScreens(source.index, destination.index);
          break;
        case "screen-templates":
          createScreenFromTemplate(
            templateList[source.index],
            destination.index
          );
          break;
        default:
          break;
      }
    },
    [createScreenFromTemplate, reorderScreens, templateList]
  );

  const handleUpdateScreenName = useCallback(
    (screen, newTitle) => {
      updateScreen({
        id: screen.id,
        title: newTitle
      });
    },
    [updateScreen]
  );

  const handleDeleteScreen = useCallback(
    screen => {
      const confirmDialogOptions = {
        title: "Delete Screen",
        contentText: "Are you sure that you want to delete this screen?",
        yesHandler: async () => {
          await deleteScreen(screen);
          closeConfirmDialog();
        },
        noHandler: () => {
          closeConfirmDialog();
        }
      };
      setState({ confirmDialogOptions });
      showConfirmDialog();
    },
    [closeConfirmDialog, deleteScreen, showConfirmDialog]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box p={1}>
              <Paper className={classes.list}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" className={classes.listTitle}>
                    Your Screens
                  </Typography>
                  <Tooltip title="These are the screens you want to appear on your TV display.  To add a new screen, drag a Screen Template into the list position of your choice.">
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </Box>
                <Box mt={2}>
                  <ScreenList
                    data={screenList}
                    onUpdateScreenName={handleUpdateScreenName}
                    onDeleteScreen={handleDeleteScreen}
                  />
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={1}>
              <Paper className={classes.list}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" className={classes.listTitle}>
                    Screen Templates
                  </Typography>
                  <Tooltip title="These templates are available for you to create your own screens.  Simply drag a template to Your Screens and place it where you would like.">
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </Box>
                <Box mt={2}>
                  <ScreenTemplateList data={templateList} />
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onRequestClose={closeConfirmDialog}
        options={state.confirmDialogOptions}
      />
    </>
  );
}

export default connect(mapState, mapDispatch)(Dashboard);
