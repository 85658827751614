export const brandColors = {
  bgColor: "#e3f2fd",
  highlightColor: "#b3e5fc",
  secondaryColor: "#ba68c8",
  tertiaryColor: "#e57373"
};

export const brandFonts = {
  largeFont: "Source+Sans+Pro:900italic",
  mediumFont: "Quattrocento+Sans",
  smallFont: "Ubuntu:700"
};

export const resolution = "1080p";
