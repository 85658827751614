import React from "react";
import Dock from "react-dock";
import { Box, Typography, IconButton } from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
var duration = 200;
var dur = duration / 1000;
var transitions = ["left", "top", "width", "height"].map(function (p) {
  return "".concat(p, " ").concat(dur, "s cubic-bezier(0, 1.5, 0.5, 1)");
}).join(",");

function HelpDock(_ref) {
  var open = _ref.open,
      onRequestClose = _ref.onRequestClose;
  return React.createElement(Dock, {
    position: "right",
    isVisible: open,
    dimStyle: {
      background: "rgba(0, 0, 100, 0.2)"
    },
    dockStyle: {
      transition: transitions
    },
    dockHiddenStyle: {
      transition: [transitions, "opacity 0.01s linear ".concat(dur, "s")].join(",")
    },
    onVisibleChange: onRequestClose
  }, React.createElement(Box, {
    display: "flex",
    flexDirection: "column",
    p: 1
  }, React.createElement(Box, {
    pt: 6,
    display: "flex",
    justifyContent: "center"
  }, React.createElement(Typography, {
    variant: "h6"
  }, "What's this page?")), React.createElement(Box, null, React.createElement(Typography, null, "Page description goes here...")), React.createElement(Box, {
    position: "absolute",
    right: "10px",
    top: "10px"
  }, React.createElement(IconButton, null, React.createElement(ClearIcon, null)))));
}

export default HelpDock;