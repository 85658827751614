import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Box, Button } from "@material-ui/core";
import Auth from "@aws-amplify/auth";

import { DeleteUserConfirmationDialog } from "@displaygecko/dg-components";
import General from "./General";
// import PaymentInformation from "./PaymentInformation";
import ResetPassword from "./ResetPassword";

import { useToggle } from "../../helpers";
import { user } from "@displaygecko/dg-modules";

const mapDispatch = {
  deleteAccount: user.deleteAccount
};

function Administration({ deleteAccount }) {
  const {
    on: confirmDialogOpen,
    setOn: showConfirmDialog,
    setOff: closeConfirmDialog
  } = useToggle();

  const handleDeleteUser = useCallback(() => {
    showConfirmDialog();
  }, [showConfirmDialog]);

  const handleDeleteUserConfirmed = useCallback(
    async reasons => {
      await deleteAccount(reasons);
      closeConfirmDialog();
      Auth.signOut();
    },
    [deleteAccount, closeConfirmDialog]
  );

  return (
    <>
      <Box>
        <General />
        {/* <Box mt={2} />
        <PaymentInformation /> */}
        <Box mt={2} />
        <ResetPassword />
        <Box mt={2} />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleDeleteUser}>Delete My Account</Button>
        </Box>
      </Box>
      <DeleteUserConfirmationDialog
        open={confirmDialogOpen}
        onRequestClose={closeConfirmDialog}
        onDelete={handleDeleteUserConfirmed}
      />
    </>
  );
}

export default connect(null, mapDispatch)(Administration);
