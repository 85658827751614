import _objectSpread from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import React from "react";

function Region(_ref) {
  var region = _ref.region;
  return React.createElement("div", {
    "data-editable": true,
    "data-name": region.id,
    style: _objectSpread({
      position: "absolute"
    }, region.size, {}, region.styles),
    dangerouslySetInnerHTML: {
      __html: region.html
    }
  });
}

export default Region;