import React from "react";
import { Box } from "@material-ui/core";

import { ColumnFlexBox } from "@displaygecko/dg-components";

function PrimaryPageLayout({ children }) {
  return (
    <ColumnFlexBox>
      <Box width="100%" maxWidth={1024} mx="auto" mt={3} overflow="hidden">
        {children}
      </Box>
    </ColumnFlexBox>
  );
}

export default PrimaryPageLayout;
