import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import PanZoom from "react-easy-panzoom";
import sizeMe from "react-sizeme";

import { ColumnFlexBox, Region, Loader } from "@displaygecko/dg-components";
import "./screen.css";

import { screen } from "@displaygecko/dg-modules";

import { initContentTools } from "../../helpers";

const mapDispatch = {
  getScreen: screen.getScreen,
  updateScreen: screen.updateScreen
};

function Screen({ size, match, getScreen, updateScreen }) {
  useEffect(() => {
    let editor = null;
    async function asyncFunc() {
      const screenId = match.params.screenId;
      await getScreen(screenId);

      setTimeout(() => {
        editor = initContentTools();

        editor.addEventListener("saved", async function(ev) {
          const regions = ev.detail().regions;
          if (Object.keys(regions).length === 0) {
            return;
          }
          // Set the editor as busy while we save our changes
          this.busy(true);

          try {
            await updateScreen({
              id: screenId,
              regionsData: regions
            });
            editor.busy(false);
            new window.ContentTools.FlashUI("ok");
          } catch (error) {
            editor.busy(false);
            new window.ContentTools.FlashUI("no");
          }
        });
      }, 0);
    }

    asyncFunc();

    return function cleanup() {
      if (editor) editor.destroy();
    };
  }, [match, getScreen, updateScreen]);

  return (
    <ColumnFlexBox>
      <PanZoom
        style={{
          height: size.height,
          overflow: "hidden"
        }}
      >
        <Box position="relative" width="1920px" height="1080px">
          <Loader
            actionCreator={screen.getScreen}
            selector={screen.screenSelector(match.params.screenId)}
          >
            {screen =>
              screen ? (
                screen.regions.map((region, index) => (
                  <Region key={index} region={region} />
                ))
              ) : (
                <Box></Box>
              )
            }
          </Loader>
        </Box>
      </PanZoom>
    </ColumnFlexBox>
  );
}

const enhance = compose(
  connect(null, mapDispatch),
  sizeMe({ monitorHeight: true })
);

export default enhance(Screen);
