export default {
  apiGateway: {
    name: "display-gecko-dev-api",
    REGION: "us-east-1",
    URL: "https://zkxzux6400.execute-api.us-east-1.amazonaws.com/dev",
    WEBSOCKET_URL: "wss://03a4ev9d53.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_tOysQlCdP",
    APP_CLIENT_ID: "2f7t2ujmmrih5bm6tvvpcsvqvv",
    IDENTITY_POOL_ID: "us-east-1:cf98935b-f0e6-4bb3-ad81-e6656a61cef4"
  }
};
