import React, { useState, useCallback } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";

import Administration from "./Administration";
import Personalization from "./Personalization";

const Components = {
  0: Administration,
  1: Personalization
};

function Configurations() {
  const [selectedTab, selectTab] = useState(0);

  const handleChange = useCallback((event, newValue) => {
    selectTab(newValue);
  }, []);

  const Component = Components[selectedTab];

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Administration" />
        <Tab label="Personalization" />
      </Tabs>
      <Box m={1} mt={2}>
        <Component />
      </Box>
    </Box>
  );
}

export default Configurations;
