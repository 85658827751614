import React from "react";

import { MainLayout } from "./Main";
import { PrimaryPageLayout } from "./PrimaryPage";

export const withMainLayout = Component => props => {
  return (
    <MainLayout>
      <Component {...props} />
    </MainLayout>
  );
};

export const withPrimaryPageLayout = Component => props => {
  return (
    <PrimaryPageLayout>
      <Component {...props} />
    </PrimaryPageLayout>
  );
};
